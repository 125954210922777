import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const paymentsLoading = () => ({
  type: ActionTypes.PAYMENTS_LOADING,
});

export const removePaymentData = () => ({
  type: ActionTypes.REMOVE_PAYMENT_DATA,
});

export const fetchGetPaymentHistory =
  (page, status, token) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/payment_history_new?page=${page}&payment_method=${status}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_PAYMENT_HISTORY,
          payload: data,
        });
      });
  };

export const fetchHandlePayment =
  (paymentMethod, duration, id, transId, packages, selectedPackages, token) =>
  async (dispatch) => {
    const body = {
      amount: duration.price,
      paymethod: paymentMethod,
      transaction_id: transId,
      duration_id: duration.id,
      post_id: id,
      featured: packages.includes("Featured") ? 1 : 0,
      urgent: packages.includes("Urgent") ? 1 : 0,
      spotlight: packages.includes("Spotlight") ? 1 : 0,
      logo: packages.includes("Logo") ? 1 : 0,
      featured_already: selectedPackages.includes("Featured") ? 1 : 0,
      urgent_already: selectedPackages.includes("Urgent") ? 1 : 0,
      logo_already: selectedPackages.includes("Logo") ? 1 : 0,
      spot_already: selectedPackages.includes("Spotlight") ? 1 : 0,
    };
    await axios
      .post(`${BaseUrl}/payment`, body, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.PAYMENT_SUCCESS,
          payload: data,
        });
      })
      .catch((e) => {
        dispatch({
          type: ActionTypes.PAYMENT_FAIL,
          payload: e?.response?.data,
        });
      });
  };
