import React, { useState } from "react";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchAddPost,
  fetchGetAdsForm,
  fetchGetEditForm,
  fetchUpdateAds,
  removeMyAdsData,
} from "../../Redux/MyAds/action";
import { Button, Loading } from "../../Component";
import Cookies from "js-cookie";
import FormHeader from "./FormHeader";
import { toast } from "react-toastify";
import { RiDeleteBin6Line, RiImageAddFill } from "react-icons/ri";
import {
  fetchDeletePostImage,
  fetchUploadPostImage,
  removePostImageData,
} from "../../Redux/PostImage/action";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { fetchGetUserProfile } from "../../Redux/Profile/action";
import { fetchGetModelByMake, removeCarData } from "../../Redux/Cars/action";
import {
  fetchGetAllCategories,
  removeCategoryData,
} from "../../Redux/Category/action";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";

const Form = ({
  firstCat,
  secCat,
  thriCat,
  frthCat,
  setToggle,
  setSecCat,
  setThriCat,
  setFrthCat,
}) => {
  const [image, setImage] = useState();
  const [makeId, setMakeId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("");
  const [values, setValues] = useState("");
  const [checkFieldsNull, setCheckFieldsNull] = useState(false);

  const dispatch = useDispatch();
  const token = Cookies.get("token");
  const navigate = useNavigate();

  const formId = useSelector((state) => state.myAds.formId, shallowEqual);

  const { id } = useParams();

  const [data, setData] = useState({});

  const loading = useSelector((state) => state.myAds.isloading, shallowEqual);
  const fields = useSelector((state) => state.myAds.fields, shallowEqual);
  const field = useSelector((state) => state.myAds.field, shallowEqual);
  const imageMsg = useSelector(
    (state) => state.postImages.message,
    shallowEqual
  );
  const imageErrMsg = useSelector(
    (state) => state.postImages.errMsg,
    shallowEqual
  );
  const imageSource = useSelector(
    (state) => state.postImages.images,
    shallowEqual
  );

  const imageDelMsg = useSelector(
    (state) => state.postImages.dMsg,
    shallowEqual
  );
  const model = useSelector((state) => state.cars.model, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const profileLoading = useSelector(
    (state) => state.profile.isloading,
    shallowEqual
  );
  const message = useSelector((state) => state.myAds.message, shallowEqual);
  const errMsg = useSelector((state) => state.myAds.errMsg, shallowEqual);

  const title = useSelector((state) => state.myAds.title, shallowEqual);
  const images = useSelector((state) => state.myAds.images, shallowEqual);
  const post = useSelector((state) => state.myAds.post, shallowEqual);
  const inspection_times = useSelector(
    (state) => state.myAds.inspection_times,
    shallowEqual
  );

  const [formData, setFormData] = useState({
    inspection_date: [""],
    start_time: [""],
    end_time: [""],
    start_hr: [""],
    start_min: [""],
    end_hr: [""],
    end_min: [""],
  });

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Ensure month and day are formatted with leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  const handleAddMore = () => {
    setFormData((prevData) => ({
      inspection_date: [...prevData.inspection_date, ""],
      start_time: [...prevData.start_time, ""],
      end_time: [...prevData.end_time, ""],
      start_hr: [...prevData.start_hr, ""],
      start_min: [...prevData.start_min, ""],
      end_hr: [...prevData.end_hr, ""],
      end_min: [...prevData.end_min, ""],
    }));
  };

  const handleChangeInspection = (index, field, value) => {
    if (field === "start_time") {
      // Extract hours and minutes from the "HH:mm" format
      const [hours, minutes] = (value || "").split(":");

      // Update start_hr, start_min, end_hr, end_min in the state
      setFormData((prevData) => ({
        ...prevData,
        [field]: (prevData[field] || []).map((item, i) =>
          i === index ? value : item
        ),
        [`${field.slice(0, 5)}_hr`]: (
          prevData[`${field.slice(0, 5)}_hr`] || []
        ).map((item, i) => (i === index ? hours : item)),
        [`${field.slice(0, 5)}_min`]: (
          prevData[`${field.slice(0, 5)}_min`] || []
        ).map((item, i) => (i === index ? minutes : item)),
      }));
    } else if (field === "end_time") {
      // Extract hours and minutes from the "HH:mm" format
      const [hours, minutes] = (value || "").split(":");

      // Update start_hr, start_min, end_hr, end_min in the state
      setFormData((prevData) => ({
        ...prevData,
        [field]: (prevData[field] || []).map((item, i) =>
          i === index ? value : item
        ),
        [`${field.slice(0, 3)}_hr`]: (
          prevData[`${field.slice(0, 3)}_hr`] || []
        ).map((item, i) => (i === index ? hours : item)),
        [`${field.slice(0, 3)}_min`]: (
          prevData[`${field.slice(0, 3)}_min`] || []
        ).map((item, i) => (i === index ? minutes : item)),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: (prevData[field] || []).map((item, i) =>
          i === index ? value : item
        ),
      }));
    }
  };

  const handleDelete = (index) => {
    setFormData((prevData) => {
      const newDate = [...prevData.inspection_date];
      const newStartTime = [...prevData.start_time];
      const newEndTime = [...prevData.end_time];
      const newStart_hr = [...prevData.start_hr];
      const newStart_min = [...prevData.start_min];
      const newEnd_hr = [...prevData.end_hr];
      const newEnd_min = [...prevData.end_min];

      newDate.splice(index, 1);
      newStartTime.splice(index, 1);
      newEndTime.splice(index, 1);
      newStart_hr.splice(index, 1);
      newStart_min.splice(index, 1);
      newEnd_hr.splice(index, 1);
      newEnd_min.splice(index, 1);

      return {
        inspection_date: newDate,
        start_time: newStartTime,
        end_time: newEndTime,
        start_hr: newStart_hr,
        start_min: newStart_min,
        end_hr: newEnd_hr,
        end_min: newEnd_min,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "make") {
      setMakeId(value);
    }
    setData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleChangeTextEditor = (content) => {
    setData((prev) => {
      return {
        ...prev,
        description: content,
      };
    });
  };

  const handleChangeAddress = async () => {
    if (value) {
      const search = value.label;

      const promises = await geocodeByAddress(search);

      const geocodes = await Promise.all(promises);

      const place = geocodes?.[0];

      const city = place?.address_components.find(
        (component) =>
          component.types.includes("sublocality_level_1") ||
          component.types.includes("sublocality") ||
          component.types.includes("political")
      );

      const district = place?.address_components.find((component) =>
        component.types.includes("administrative_area_level_2")
      );

      setData((prev) => {
        return {
          ...prev,
          ad_address: place?.formatted_address,
          property_area: city?.long_name ? city?.long_name : "",
          property_street_name: city?.long_name ? city?.long_name : "",
          property_district: district?.long_name ? district?.long_name : "",
          display_address: city?.long_name ? city?.long_name : "",
          location: `${
            city?.long_name && district?.long_name
              ? `${city?.long_name},${district?.long_name}`
              : place?.formatted_address
          }`,
        };
      });
    }
  };

  useEffect(() => {
    handleChangeAddress();
  }, [value]);

  const handleChangeProfileAddress = async () => {
    if (values) {
      const search = values.label;

      const promises = await geocodeByAddress(search);

      const geocodes = await Promise.all(promises);

      const place = geocodes?.[0];

      const city = place?.address_components.find(
        (component) =>
          component.types.includes("sublocality_level_1") ||
          component.types.includes("sublocality") ||
          component.types.includes("political")
      );

      const district = place?.address_components.find((component) =>
        component.types.includes("administrative_area_level_2")
      );

      if (
        firstCat.name === "Real Estate" ||
        title?.[0] === "Real Estate" ||
        title?.[0] === "PG/Flatmates"
      ) {
        setData((prev) => {
          return {
            ...prev,
            address: place?.formatted_address,
          };
        });
      } else {
        setData((prev) => {
          return {
            ...prev,
            address: place?.formatted_address,

            location: `${
              city?.long_name && district?.long_name
                ? `${city?.long_name},${district?.long_name}`
                : place?.formatted_address
            }`,
          };
        });
      }
    }
  };

  useEffect(() => {
    handleChangeProfileAddress();
  }, [values]);

  const handleChangeMultiple = (e, value) => {
    const { name, checked } = e.target;
    const featureType = name;

    setData((prevData) => {
      if (checked) {
        // Add the value to the array if checked
        return {
          ...prevData,
          [featureType]: [...(prevData[featureType] || []), value],
        };
      } else {
        // Remove the value from the array if unchecked
        return {
          ...prevData,
          [featureType]: (prevData[featureType] || []).filter(
            (val) => val !== value
          ),
        };
      }
    });
  };

  const uploadImage = (e) => {
    if (e.target.files) {
      const fileType = Array.from(e.target.files);
      const formData = new FormData();
      fileType.forEach((img, i) => {
        formData.append(`files[]`, img);
      });
      dispatch(fetchUploadPostImage(formData, token));
    }
  };

  const handleDeleteImage = (img) => {
    setImage(img);
    const body = {
      image_name: img,
    };

    dispatch(fetchDeletePostImage(body, token));
  };

  const handleSUbmit = (e) => {
    e.preventDefault();

    // Simple validation: Check if required fields are empty
    const requiredFields = fields.filter((field) => field.is_required);
    const isEmpty = requiredFields.some((field) => !data[field?.attr?.name]);

    const requiredField = field.filter(
      (field) => field?.attrs?.required === "1"
    );
    const isEmpty2 = requiredField.some((field) => !data[field?.attrs?.name]);

    if (
      isEmpty ||
      isEmpty2 ||
      data?.ad_title === "" ||
      !data?.ad_title ||
      data?.description === "" ||
      !data?.description === ""
    ) {
      toast.error("Please fill in all required fields.");
    } else if (!data?.location || data?.location === "") {
      toast.error("Please select location");
    } else if (
      title?.[0] !== "Jobs" &&
      (data?.ad_price === "" || !data?.ad_price) &&
      data?.price_type !== "3"
    ) {
      toast.error("Please enter price");
    } else if (
      data?.ad_price &&
      data?.to_ad_price &&
      Number(data?.ad_price) > Number(data?.to_ad_price)
    ) {
      toast.error("Max price for your ad must be greater than min price");
    } else if (
      (firstCat.name === "Real Estate" ||
        title?.[0] === "Real Estate" ||
        title?.[0] === "PG/Flatmates") &&
      !data.postImages
    ) {
      toast.error("Image required");
    } else {
      setIsLoading(true);
      id
        ? dispatch(fetchUpdateAds(id, data, token))
        : dispatch(fetchAddPost(data, token));
    }
  };

  // get model for automativ categry
  useEffect(() => {
    makeId && dispatch(fetchGetModelByMake(makeId, token));
    return () => {
      dispatch(removeCarData());
    };
  }, [makeId]);

  // append inspection date and time
  useEffect(() => {
    formData &&
      setData((prevData) => {
        return {
          ...prevData,
          inspection_date: formData.inspection_date,
          start_time: formData.start_time,
          start_hr: formData.start_hr,
          start_min: formData.start_min,
          end_time: formData.end_time,
          end_hr: formData.end_hr,
          end_min: formData.end_min,
        };
      });
  }, [formData]);

  useEffect(() => {
    if (
      firstCat.name === "Real Estate" ||
      title?.[0] === "Real Estate" ||
      title?.[0] === "PG/Flatmates"
    ) {
      profile &&
        setData((prev) => {
          return {
            ...prev,
            name: profile.first_name + " " + profile.last_name,
            email: profile.user_email,
            phone: profile.user_mobile,
            address: profile.street_address,
            show_phone: 1,
          };
        });
    } else {
      profile &&
        setData((prev) => {
          return {
            ...prev,
            name: profile.first_name + " " + profile.last_name,
            email: profile.user_email,
            phone: profile.user_mobile,
            address: profile.street_address,
            show_phone: 1,
            location: profile.street_address,
          };
        });
    }
  }, [profile]);

  useEffect(() => {
    formId &&
      setData((prev) => {
        return {
          ...prev,
          form_id: formId,
        };
      });
  }, [formId]);

  // edit form condition
  // for images
  useEffect(() => {
    id &&
      images &&
      images.length &&
      setData((prevData) => {
        return {
          ...prevData,
          postImages: [
            ...(prevData["postImages"] || []),

            images.map((val) => {
              return {
                image_name: val.image_name,
                thumbnail: val.thumbnail,
              };
            }),
          ].flat(),
        };
      });
  }, [id && images && images.length]);

  // for inspection time
  useEffect(() => {
    // Map the inspection_times array to update the formData state
    const mappedFormData =
      inspection_times &&
      inspection_times.map((inspection) => ({
        inspection_date: inspection.inspection_date,
        start_time: `${inspection.start_hr}:${inspection.start_min}`,
        end_time: `${inspection.end_hr}:${inspection.end_min}`,
        start_hr: inspection.start_hr,
        start_min: inspection.start_min,
        end_hr: inspection.end_hr,
        end_min: inspection.end_min,
      }));

    // Update the state with the mapped data
    setFormData({
      inspection_date:
        mappedFormData && mappedFormData.map((data) => data.inspection_date),
      start_time:
        mappedFormData && mappedFormData.map((data) => data.start_time),
      end_time: mappedFormData && mappedFormData.map((data) => data.end_time),
      start_hr: mappedFormData && mappedFormData.map((data) => data.start_hr),
      start_min: mappedFormData && mappedFormData.map((data) => data.start_min),
      end_hr: mappedFormData && mappedFormData.map((data) => data.end_hr),
      end_min: mappedFormData && mappedFormData.map((data) => data.end_min),
    });
  }, [inspection_times && inspection_times.length]);

  // for static fields
  useEffect(() => {
    id &&
      post &&
      setData((prevData) => {
        return {
          ...prevData,
          ad_title: post.title,
          description: post.description,
          ad_price: post.price,
          to_ad_price: post.to_price,
          property_house_number: post.property_house_number,
          property_postcode: post.property_postcode,
          property_state: post.property_state,
          property_street_name: post.property_street_name,
          property_suburb: post.property_suburb,
          ad_address: post.ad_address,
          location: post?.ads_data?.location,
          show_price: post?.show_price,
          postcode: post?.post_code ? post?.post_code : post.property_postcode,
          display_address: post?.display_address,
          address: post?.address,
          location: post?.ad_address ? post?.ad_address : post?.address,
        };
      });

    id && post && setMakeId(post.make_id);
  }, [id && post]);

  // dynamic field
  useEffect(() => {
    id &&
      field &&
      field.map((val) => {
        return val?.attrs?.type === "multiple"
          ? val?.attrs?.value.map((itm) => {
              return setData((prevData) => {
                return {
                  ...prevData,
                  [val?.attrs?.name]: [
                    ...(prevData[val?.attrs?.name] || []),
                    parseInt(itm.id),
                  ],
                };
              });
            })
          : setData((preData) => {
              return {
                ...preData,
                [val?.attrs?.name]: val?.attrs?.value ? val?.attrs?.value : "",
              };
            });
      });
  }, [id && field && field.length]);

  // dynamic fields
  useEffect(() => {
    id &&
      fields &&
      fields.map((val) => {
        return val?.attr?.type === "multiple"
          ? val?.attr?.value.map((itm) => {
              return setData((prevData) => {
                return {
                  ...prevData,
                  [val?.attr?.name]: [
                    ...(prevData[val?.attr?.name] || []),
                    parseInt(itm.id),
                  ],
                };
              });
            })
          : val?.attr?.type === "datepicker"
          ? setData((prevData) => {
              return {
                ...prevData,
                [val?.attr?.name]: moment(
                  val?.attr?.value,
                  "DD-MM-YYYY"
                ).format("YYYY-MM-DD"),
              };
            })
          : setData((preData) => {
              return {
                ...preData,
                [val?.attr?.name]: val?.attr?.value ? val?.attr?.value : "",
              };
            });
      });
  }, [id && fields && fields.length]);

  // checking fields emmtiness
  useEffect(() => {
    const checkfields =
      fields &&
      fields.filter(
        (val) =>
          val?.attr?.name !== "ad_status" &&
          val?.attr?.name !== "datepicker" &&
          val?.attr?.name !== "price_type2" &&
          val?.attr?.name !== "price_type"
      );

    setCheckFieldsNull(checkfields && checkfields.length ? false : true);
  }, [fields]);

  useEffect(() => {
    message && toast.success(message);
    message && setIsLoading(false);
    message && navigate(`/me/my-ads`);
    message && dispatch(removeMyAdsData());
    message && dispatch(removeCategoryData());
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && setIsLoading(false);
  }, [errMsg]);

  useEffect(() => {
    imageMsg &&
      setData((prevData) => {
        return {
          ...prevData,
          postImages: [
            ...(prevData["postImages"] || []),

            imageSource.map((val) => {
              return {
                image_name: val.image_name,
                thumbnail: val.image_thumnail_name,
              };
            }),
          ].flat(),
        };
      });

    imageMsg && dispatch(removePostImageData());
  }, [imageMsg]);

  useEffect(() => {
    imageDelMsg &&
      setData((prevData) => {
        return {
          ...prevData,
          postImages: [
            ...(prevData["postImages"] || []),
            imageSource.map((val) => {
              return {
                image_name: val.image_name,
                thumbnail: val.image_thumnail_name,
              };
            }),
          ]
            .flat()
            .filter((val) => val.image_name !== image),
        };
      });
    imageDelMsg && dispatch(removePostImageData());
  }, [imageDelMsg]);

  useEffect(() => {
    imageErrMsg && toast.error(imageErrMsg);
    imageErrMsg && dispatch(removePostImageData());
  }, [imageErrMsg]);

  useEffect(() => {
    dispatch(fetchGetUserProfile(token));
    dispatch(fetchGetAllCategories());
    id
      ? dispatch(fetchGetEditForm(id, token))
      : dispatch(
          fetchGetAdsForm(firstCat.id, secCat.id, thriCat.id, frthCat.id, token)
        );

    return () => {
      dispatch(removeMyAdsData());
    };
  }, []);

  if (loading || profileLoading || isLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="w-full relative">
      <h6 className="font-semibold text-2xl">
        Ad <span className="text-primary">Details</span>
      </h6>
      <p className="text-base mt-2">
        Please fill out the form to add your post
      </p>

      <FormHeader
        firstCat={firstCat}
        secCat={secCat}
        thriCat={thriCat}
        frthCat={frthCat}
        setToggle={setToggle}
        setSecCat={setSecCat}
        setThriCat={setThriCat}
        setFrthCat={setFrthCat}
      />

      <div className="w-full grid grid-cols-12 gap-x-5 mt-10 relative h-fit">
        {/* left side */}
        <div className="w-full col-span-12 md:col-span-7">
          {/* ad content */}
          <div className="w-full border rounded-xl bg-white">
            <h6 className="font-semibold p-5 text-lg">Ad Content</h6>
            <hr className="w-full" />
            <div className="w-full p-5">
              {fields &&
                fields.map((val, i) => {
                  return val?.attr?.name === "ad_status" ? (
                    <div className="text-sm" key={i}>
                      <label className="pb-2 font-medium flex  text-sm items-center gap-x-1">
                        {val?.attr?.label}{" "}
                        {val.is_required ? (
                          <span className="text-red-500">*</span>
                        ) : (
                          ""
                        )}
                      </label>

                      <select
                        name={val?.attr?.name}
                        onChange={handleChange}
                        value={data[val?.attr?.name]}
                        required={val?.attr?.name}
                        className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                      >
                        <option value="" selected disabled>
                          Select Status
                        </option>
                        {val?.childs?.map((itm) => {
                          return (
                            <option key={itm.value} value={itm.value}>
                              {itm.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : null;
                })}
              <div className="text-sm mt-5">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label className="font-medium flex text-sm items-center gap-x-1 w-full">
                    Ad Title
                    <span className="text-red-500">*</span>
                  </label>
                </div>

                <input
                  type="text"
                  name="ad_title"
                  required={true}
                  value={data?.ad_title}
                  onChange={handleChange}
                  className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                  placeholder="Enter your ad title"
                />
              </div>

              <div className="text-sm mt-5">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label
                    required
                    className="font-medium flex text-sm items-center gap-x-1 w-full"
                  >
                    Ad Description
                    <span className="text-red-500">*</span>
                  </label>
                </div>

                <div className="border overflow-y-auto h-72">
                  <ReactQuill
                    theme="snow"
                    name="description"
                    required="description"
                    onChange={handleChangeTextEditor}
                    value={data?.description}
                    placeholder="Type your ads description here"
                    className="h-60"
                  />
                </div>
              </div>
              {/* images */}
              <div className="mt-5">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label className="font-medium flex text-sm items-center justify-between w-full">
                    Images
                  </label>
                </div>
                <p className="mt-1 pb-3 text-xs text-gray-400">
                  First image is your product’s cover image that will be
                  highlighted everywhere.
                </p>
                <div className="flex w-full flex-wrap items-center gap-x-5">
                  <div className="mt-5">
                    <label>
                      <div
                        title="upload image"
                        className="h-16 w-16 sm:h-20 xl:h-24 sm:w-20 xl:w-24 flex justify-center items-center cursor-pointer border-2 border-gray-300 bg-gray-200 hover:bg-gray-300"
                      >
                        <RiImageAddFill className="text-4xl text-gray-600" />
                        <input
                          type="file"
                          multiple
                          accept="image/jpg, image/png, image/webp, image/jpeg"
                          onChange={(e) => uploadImage(e)}
                          className="hidden h-40"
                        />
                      </div>
                    </label>
                  </div>
                  {data.postImages &&
                    data.postImages.map((val, i) => {
                      return (
                        <div
                          className="h-16 w-16 sm:h-20 xl:h-24 sm:w-20 xl:w-24 rounded-lg mt-5 relative"
                          key={i}
                        >
                          <div
                            className="absolute -right-1 -top-2 rounded-full bg-white border cursor-pointer hover:bg-red-500"
                            onClick={() => handleDeleteImage(val.image_name)}
                          >
                            <MdDeleteForever className=" text-red-500 text-xl hover:text-white" />
                          </div>
                          <img
                            src={val.image_name}
                            alt=""
                            className="w-full h-full object-fill"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* ad price */}
          <div className="w-full border rounded-xl bg-white mt-10">
            <h6 className="font-semibold p-5 text-lg">Price</h6>
            <hr className="w-full" />
            <div className="w-full p-5">
              {fields &&
                fields.map((val, i) => {
                  return (
                    <div key={i} className={`${i === 0 ? "mt-0" : "mt-5"}`}>
                      {val?.attr?.name === "price_type" &&
                      val?.attr?.name !== "ad_status" ? (
                        <div className="text-sm">
                          <label className="pb-2 font-medium flex text-textPrimary text-sm items-center gap-x-1">
                            {val?.attr?.label}
                            {val.is_required ? (
                              <span className="text-red-500">*</span>
                            ) : (
                              ""
                            )}
                          </label>

                          <select
                            name={val?.attr?.name}
                            onChange={handleChange}
                            value={data[val?.attr?.name]}
                            className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                          >
                            <option value="" selected disabled>
                              Select Price Type
                            </option>
                            {val?.childs?.map((itm, i) => {
                              return (
                                <option key={i} value={itm.value}>
                                  {itm.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}
                    </div>
                  );
                })}

              {data && data.price_type ? (
                data.price_type === "3" ? null : (
                  <div className="text-sm">
                    <div className="w-full flex items-center gap-x-5 pb-2">
                      <label
                        required
                        className="font-medium flex text-sm items-center gap-x-1 w-full"
                      >
                        Price
                        {title?.[0] === "Jobs" ? null : (
                          <span className="text-red-500">*</span>
                        )}
                      </label>
                    </div>
                    <div className="w-full flex items-center gap-x-5 justify-between">
                      <div className="w-full flex items-center border border-gray-300 text-sm rounded-lg outline-gray-300 overflow-hidden">
                        <p className="bg-gray-100 p-3">Npr</p>
                        <input
                          type="text"
                          name="ad_price"
                          onChange={handleChange}
                          value={data?.ad_price}
                          className="p-3 outline-none w-full"
                          placeholder="Enter your price"
                        />
                      </div>

                      {data.price_type === "2" ? (
                        <div className="w-full flex items-center border border-gray-300 text-sm rounded-lg outline-gray-300 overflow-hidden">
                          <p className="bg-gray-100 p-3">Npr</p>
                          <input
                            type="text"
                            name="to_ad_price"
                            onChange={handleChange}
                            value={data?.to_ad_price}
                            className="p-3 outline-none w-full"
                            placeholder="Enter your max price"
                            min={data.ad_price}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
              ) : null}

              {fields &&
                fields.map((val, i) => {
                  return (
                    <div key={i} className="mt-5">
                      {val?.attr?.name === "price_type2" &&
                      val?.attr?.name !== "ad_status" ? (
                        <div className="text-sm" key={i}>
                          <label className="pb-2 font-medium flex text-textPrimary text-sm items-center gap-x-1">
                            Pricing
                            {val.is_required ? (
                              <span className="text-red-500">*</span>
                            ) : (
                              ""
                            )}
                          </label>

                          <select
                            name={val?.attr?.name}
                            onChange={handleChange}
                            value={data[val?.attr?.name]}
                            className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                          >
                            <option value="" selected disabled>
                              Select Pricing Type
                            </option>
                            {val?.childs?.map((itm, i) => {
                              return (
                                <option key={i} value={itm.value}>
                                  {itm.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      ) : null}
                    </div>
                  );
                })}

              <div className="text-sm">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label
                    required
                    className="font-medium flex text-sm items-center gap-x-1 w-full"
                  >
                    Show Price in Ad
                    <span className="text-red-500">*</span>
                  </label>
                </div>

                <div className="w-full flex items-center gap-x-5">
                  <div className="flex items-center gap-x-2">
                    <input
                      type="radio"
                      name="show_price"
                      value="Yes"
                      checked={data.show_price === "Yes"}
                      onChange={handleChange}
                    />
                    <p>Yes</p>
                  </div>

                  <div className="flex items-center gap-x-2">
                    <input
                      type="radio"
                      name="show_price"
                      value="No"
                      onChange={handleChange}
                      checked={data.show_price === "No"}
                    />
                    <p>No</p>
                  </div>
                </div>
              </div>
              {fields &&
                fields.map((val, i) => {
                  return (
                    <div className="mt-5" key={i}>
                      {val?.attr?.type === "datepicker" ? (
                        <div className="text-sm mt-5">
                          <div className="w-full flex items-center gap-x-5 pb-2">
                            <label
                              required
                              className="font-medium flex text-sm items-center gap-x-1 w-full"
                            >
                              {val?.attr?.label}

                              {val.is_required ? (
                                <span className="text-red-500">*</span>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                          <input
                            type="date"
                            name={val?.attr?.name}
                            onChange={handleChange}
                            value={data[val?.attr?.name]}
                            min={moment().local().format("YYYY-MM-DD")}
                            className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          </div>

          {/* ad details */}
          {checkFieldsNull && field && !field.length ? null : (
            <div className="w-full border rounded-xl bg-white mt-10">
              <h6 className="font-semibold p-5 text-lg">Ad Details</h6>
              <hr className="w-full" />
              <div className="w-full p-5">
                {fields &&
                  fields.map((val, i) => {
                    return (
                      <div key={i} className="mt-5">
                        {val?.attr?.type === "text" &&
                        val?.attr?.name !== "ad_status" &&
                        val?.attr?.type !== "google_address" ? (
                          <div className="text-sm">
                            <div className="w-full flex items-center gap-x-5 pb-2">
                              <label
                                required
                                className="font-medium flex text-sm items-center gap-x-1 w-full"
                              >
                                {val?.attr?.label}

                                {val.is_required ? (
                                  <span className="text-red-500">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>

                            <input
                              type="text"
                              name={val?.attr?.name}
                              onChange={handleChange}
                              value={data[val?.attr?.name]}
                              className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                              placeholder={
                                val?.attr?.name === "area" ||
                                val?.attr?.name === "house_area"
                                  ? "M * M"
                                  : val?.attr?.label
                              }
                            />
                          </div>
                        ) : val?.attr?.type === "google_address" ? (
                          <div className="text-sm">
                            <div className="w-full flex items-center gap-x-5 pb-2">
                              <label
                                required
                                className="font-medium flex text-sm items-center gap-x-1 w-full"
                              >
                                {val?.attr?.label}
                                <span className="text-red-500">*</span>
                              </label>
                            </div>

                            <GooglePlacesAutocomplete
                              textInputProps={{
                                placeholderTextColor: "#32a852",
                                returnKeyType: "search",
                              }}
                              autocompletionRequest={{
                                componentRestrictions: { country: ["NP"] },
                              }}
                              selectProps={{
                                value,
                                onChange: setValue,
                                isMulti: false,
                                placeholder: data?.ad_address,
                              }}
                            />
                          </div>
                        ) : val?.attr?.type !== "multiple" &&
                          val?.attr.type !== "datepicker" &&
                          val?.attr?.name !== "ad_status" &&
                          val?.attr?.name !== "price_type" &&
                          val?.attr?.name !== "price_type2" &&
                          val?.attr?.name !== "model" &&
                          val?.attr?.type !== "google_address" ? (
                          <div className="text-sm">
                            <label className="pb-2 font-medium flex text-textPrimary text-sm items-center gap-x-1">
                              {val?.attr?.label}
                              {val.is_required ? (
                                <span className="text-red-500">*</span>
                              ) : (
                                ""
                              )}
                            </label>

                            <select
                              name={val?.attr?.name}
                              onChange={handleChange}
                              value={data[val?.attr?.name]}
                              className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                            >
                              <option value="" selected disabled>
                                Select {val?.attr?.label}
                              </option>
                              {val?.childs?.map((itm) => {
                                return (
                                  <option key={itm.value} value={itm.value}>
                                    {itm.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : val?.attr?.name === "model" ? (
                          <div className="text-sm">
                            <label className="pb-2 font-medium flex text-textPrimary text-sm items-center gap-x-1">
                              {val?.attr?.label}
                              {val.is_required ? (
                                <span className="text-red-500">*</span>
                              ) : (
                                ""
                              )}
                            </label>

                            <select
                              name={val?.attr?.name}
                              onChange={handleChange}
                              value={data[val?.attr?.name]}
                              className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                            >
                              <option value="" selected disabled>
                                Select {val?.attr?.label}
                              </option>
                              {model?.map((itm) => {
                                return (
                                  <option key={itm.value} value={itm.value}>
                                    {itm.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        ) : val?.attr?.type === "multiple" ? (
                          <div className="text-sm">
                            <div className="w-full flex items-center gap-x-5 pb-2">
                              <label
                                required
                                className="font-medium flex text-sm items-center gap-x-1 w-full"
                              >
                                {val?.attr?.label}
                                {val.is_required ? (
                                  <span className="text-red-500">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>

                            <div className="w-full grid grid-cols-2 gap-x-5">
                              {val?.childs.map((itm, i) => {
                                return (
                                  <div
                                    className="flex items-center gap-x-2 mt-5"
                                    key={i}
                                  >
                                    <input
                                      type="checkbox"
                                      name={val?.attr?.name}
                                      value={itm.value}
                                      checked={data[val?.attr?.name]?.includes(
                                        itm.value
                                      )}
                                      onChange={(e) =>
                                        handleChangeMultiple(e, itm.value)
                                      }
                                    />
                                    <p>{itm.label}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}

                {field &&
                  field.map((val, i) => {
                    return (
                      <div key={i} className="mt-5">
                        {val?.attrs?.type === "text" ? (
                          <div className="text-sm">
                            <div className="w-full flex items-center gap-x-5 pb-2">
                              <label
                                required
                                className="font-medium flex text-sm items-center gap-x-1 w-full"
                              >
                                {val?.attrs?.label}
                                {val?.attrs?.required === "1" ? (
                                  <span className="text-red-500">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>

                            <input
                              type="text"
                              name={val?.attrs?.name}
                              value={data[val?.attrs?.name]}
                              onChange={handleChange}
                              className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                              placeholder={
                                val?.attr?.name === "area" ||
                                val?.attr?.name === "house_area"
                                  ? "M * M"
                                  : val?.attr?.label
                              }
                            />
                          </div>
                        ) : val?.attrs?.type === "multiple" ? (
                          <div className="text-sm">
                            <div className="w-full flex items-center gap-x-5 pb-2">
                              <label
                                required
                                className="font-medium flex text-sm items-center gap-x-1 w-full"
                              >
                                {val?.attrs?.label}
                                {val?.attrs?.required === "1" ? (
                                  <span className="text-red-500">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>

                            <div className="w-full grid grid-cols-2 gap-x-5">
                              {val?.childs.map((itm, i) => {
                                return (
                                  <div
                                    className="flex items-center gap-x-2 mt-5"
                                    key={i}
                                  >
                                    <input
                                      type="checkbox"
                                      name={val?.attrs?.name}
                                      value={itm.value}
                                      checked={data[val?.attrs?.name]?.includes(
                                        itm.value
                                      )}
                                      onChange={(e) =>
                                        handleChangeMultiple(e, itm.value)
                                      }
                                    />
                                    <p>{itm.label}</p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : val?.attrs?.type === "datepicker" ? (
                          <div className="text-sm mt-5">
                            <div className="w-full flex items-center gap-x-5 pb-2">
                              <label
                                required
                                className="font-medium flex text-sm items-center gap-x-1 w-full"
                              >
                                {val?.attrs?.label}
                                {val?.attrs?.required === "1" ? (
                                  <span className="text-red-500">*</span>
                                ) : (
                                  ""
                                )}
                              </label>
                            </div>
                            <input
                              type="date"
                              name={val?.attrs?.name}
                              onChange={handleChange}
                              value={data[val?.attrs?.name]}
                              max={
                                val?.attrs?.label === "Pet's Date Birth"
                                  ? getCurrentDate()
                                  : ""
                              }
                              min={
                                val?.attrs?.name === "Available"
                                  ? moment().local().format("YYYY-MM-DD")
                                  : ""
                              }
                              className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                            />
                          </div>
                        ) : (
                          <div className="text-sm">
                            <label className="pb-2 font-medium flex text-textPrimary text-sm items-center gap-x-1">
                              {val?.attrs?.label}
                              {val?.attrs?.required === "1" ? (
                                <span className="text-red-500">*</span>
                              ) : (
                                ""
                              )}
                            </label>

                            <select
                              name={val?.attrs?.name}
                              onChange={handleChange}
                              value={data[val?.attrs?.name]}
                              className="w-full px-2 py-3 border rounded-lg outline-gray-300 text-secondary"
                            >
                              {val?.childs?.map((itm) => {
                                return (
                                  <option key={itm.value} value={itm.value}>
                                    {itm.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          {/* inspection date */}
          {firstCat.name === "Real Estate" || title?.[0] === "Real Estate" ? (
            <div className="w-full h-fit border rounded-xl bg-white mt-10">
              <h6 className="font-semibold p-5 text-lg">Inspection Time</h6>
              <hr className="w-full" />
              <div className="w-full p-5">
                {formData?.inspection_date?.map((_, index) => (
                  <div
                    key={index}
                    className="w-full flex items-center gap-x-3 mt-3"
                  >
                    <div className="text-sm w-full">
                      <label className="font-medium flex text-sm items-center justify-between w-full">
                        Pick a Date:
                      </label>
                      <input
                        type="date"
                        value={formData.inspection_date[index]}
                        onChange={(e) =>
                          handleChangeInspection(
                            index,
                            "inspection_date",
                            e.target.value
                          )
                        }
                        className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                      />
                    </div>

                    <div className="text-sm w-full">
                      <label className="font-medium flex text-sm items-center justify-between w-full">
                        Start Time:
                      </label>
                      <input
                        type="time"
                        value={formData.start_time[index]}
                        onChange={(e) =>
                          handleChangeInspection(
                            index,
                            "start_time",
                            e.target.value
                          )
                        }
                        className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                      />
                    </div>

                    <div className="w-full text-sm">
                      <label className="font-medium flex text-sm items-center justify-between w-full">
                        End Time:
                      </label>
                      <input
                        type="time"
                        value={formData.end_time[index]}
                        onChange={(e) =>
                          handleChangeInspection(
                            index,
                            "end_time",
                            e.target.value
                          )
                        }
                        className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                      />
                    </div>

                    <div
                      className="w-fit p-3 rounded-full bg-gray-100 cursor-pointer"
                      onClick={() => handleDelete(index)}
                    >
                      <RiDeleteBin6Line className="text-red-500" />
                    </div>
                  </div>
                ))}
              </div>
              <hr className="w-full" />
              <div className="w-full p-5">
                <Button
                  handleClick={handleAddMore}
                  value="Add New Time"
                  className="w-full rounded-xl py-3 bg-jobPrimary text-white"
                />
              </div>
            </div>
          ) : null}
        </div>

        {/* right side */}
        <div className="w-full md:sticky md:h-fit md:top-28 md:bottom-0 col-start-1 md:col-start-8 col-end-13 mt-10 md:mt-0">
          {/* contact person detail */}
          <div className="w-full h-fit border rounded-xl bg-white">
            <h6 className="font-semibold p-5 text-lg">Contact Details</h6>
            <hr className="w-full" />
            <div className="w-full p-5">
              <div className="text-sm">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label
                    required
                    className="font-medium flex text-sm items-center justify-between w-full"
                  >
                    Name
                  </label>
                </div>

                <input
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                  className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                  placeholder="Enter your name"
                />
              </div>

              <div className="text-sm mt-5">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label
                    required
                    className="font-medium flex text-sm items-center justify-between w-full"
                  >
                    Email
                  </label>
                </div>

                <input
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                  className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                  placeholder="Enter your email"
                  readOnly
                />
              </div>

              <div className="text-sm mt-5">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label
                    required
                    className="font-medium flex text-sm items-center justify-between w-full"
                  >
                    Phone Number
                  </label>
                </div>

                <input
                  type="text"
                  name="phone"
                  value={data.phone}
                  onChange={handleChange}
                  className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                  placeholder="Enter your phone"
                  readOnly
                />
              </div>

              <div className="text-sm mt-5">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label
                    required
                    className="font-medium flex text-sm items-center justify-between w-full"
                  >
                    Address
                  </label>
                </div>

                <GooglePlacesAutocomplete
                  textInputProps={{
                    placeholderTextColor: "#32a852",
                    returnKeyType: "search",
                  }}
                  autocompletionRequest={{
                    componentRestrictions: { country: ["NP"] },
                  }}
                  selectProps={{
                    values,
                    onChange: setValues,
                    isMulti: false,
                    placeholder: data?.address,
                  }}
                />
              </div>

              <Button
                value="Submit"
                handleClick={handleSUbmit}
                className="w-full bg-primary text-white rounded-lg py-3 mt-5"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
