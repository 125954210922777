import React, { useEffect, useState } from "react";
import { googleIcon, logo } from "../../Assets/Assest";
import { Form, Formik } from "formik";
import { Button, FormikControl, Loading } from "../../Component";
import {
  fetchSignup,
  fetchSocialLogin,
  removeAuthData,
} from "../../Redux/Auth/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  const message = useSelector((state) => state.auth.message, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  let code = params.get("code");

  //   initial values
  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    refferal_code: code ? code : "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("Required*"),
    last_name: Yup.string().required("Required*"),
    email: Yup.string().required("Required*"),
    password: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    localStorage.setItem("email", values.email);
    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      refferal_code: code ? code : "",
    };

    dispatch(fetchSignup(body));
    setInProgress(true);

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get("https://www.googleapis.com/oauth2/v1/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        })
        .then(({ data }) => {
          const body = {
            email: data.email,
            first_name: data.given_name,
            last_name: data.family_name,
            refferal_code: code ? code : "",
            token: data.id,
          };
          scrollToTop();
          dispatch(fetchSocialLogin(body));
        });
    },
  });

  useEffect(() => {
    message && toast.success(message);
    message && navigate("/auth/verify");
    message && setInProgress(false);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setInProgress(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (inProgress) {
    return (
      <div className="w-full h-screen bg-transparent">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-12 md:py-28 bg-slate-200 w-full h-full">
      <div className="w-11/12 xl:w-3/4 mx-auto h-full rounded-xl mt-14 bg-white grid grid-cols-12 lg:divide-x-2 pb-10 lg:pb-0">
        <div className="col-span-12 lg:col-span-6 xl:col-span-7 w-full h-full py-5 px-7">
          <img src={logo} width="200" height="200" alt="logo" />
          <h6 className="font-semibold text-xl md:text-2xl mt-7">
            Welcome to Classibazaar Nepal!
          </h6>
          <p className="text-secondary text-sm py-2">Create your account</p>
          <div className="mt-8">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              validateOnMount
            >
              {(formik) => (
                <Form>
                  <div className="w-full">
                    <FormikControl
                      label="First Name"
                      control="input"
                      name="first_name"
                      placeholder="Enter Your First Name"
                    />
                  </div>

                  <div className="w-full mt-5">
                    <FormikControl
                      label="Last Name"
                      control="input"
                      name="last_name"
                      placeholder="Enter Your Last Name"
                    />
                  </div>

                  <div className="w-full mt-5">
                    <FormikControl
                      label="Email"
                      control="input"
                      name="email"
                      placeholder="Enter Your Email"
                    />{" "}
                    v
                  </div>
                  <div className="w-full mt-5">
                    <FormikControl
                      label="Password"
                      control="password"
                      name="password"
                      placeholder="Enter Your Password"
                      type={`${showPassword ? "text" : "password"}`}
                      setShowPassword={setShowPassword}
                      showPassword={showPassword}
                    />
                  </div>
                  {code ? (
                    <div className="w-full mt-5">
                      <FormikControl
                        label="Referral Code"
                        control="input"
                        name="refferal_code"
                        placeholder="Your Referral Code"
                        readOnly
                      />
                    </div>
                  ) : null}

                  <p className="text-secondary text-sm mt-8">
                    By proceeding, you agree to our
                  </p>
                  <div className="flex items-center gap-x-3 text-sm mt-5">
                    <Link to="/terms-of-use">
                      <p className="text-secondary hover:underline cursor-pointer font-medium">
                        Terms of Service
                      </p>
                    </Link>
                    <div className="border-r-2 h-4 text-textSecondary"></div>
                    <Link to="/privacy-policy">
                      <p className="text-secondary hover:underline cursor-pointer font-medium">
                        Privacy Policies
                      </p>
                    </Link>
                  </div>

                  <div className="mt-7 w-full flex items-center flex-wrap md:flex-nowrap justify-between">
                    <Button
                      value="Register"
                      className="w-full rounded-xl bg-primary text-white py-2 md:py-3"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <div className="col-start-1 lg:col-start-7 xl:col-start-8 col-end-13 w-11/12 mx-auto h-full py-5 px-3 md:px-8 mt-7 md:mt-10 lg:mt-0 border-t lg:border-t-0">
          <div className="flex items-center flex-wrap md:flex-nowrap justify-center w-full mx-auto gap-x-5">
            <p className="text-sm text-secondary text-center md:text-start">
              Already have account?
            </p>
            <Button
              value="Sign in"
              handleClick={() => navigate("/auth/sign-in")}
              className="border rounded-full mt-5 md:mt-0 text-primary py-2 w-fit px-10 hover:bg-primary hover:text-white transition-all ease-in-out duration-300"
            />
          </div>
          <div className="w-full md:w-2/3 mx-auto lg:w-full h-full mt-14 md:mt-20 lg:mt-32">
            <div className=" flex justify-center gap-x-4 md:gap-x-7 xl:gap-x-5 2xl:gap-x-7 items-center w-full">
              <hr className="w-full xl:w-1/2 2xl:w-full text-textSecondary border" />
              <p className="w-full text-xs md:text-sm">Or Sign in with</p>
              <hr className="w-full xl:w-1/2 wxl:w-full text-textSecondary border" />
            </div>
            <div
              className="w-full rounded-full py-3 px-5 md:pr-16 cursor-pointer mt-10 border flex justify-around items-center transition duration-500 ease-in-out hover:border-primary hover:text-primary"
              onClick={() => googleLogin()}
            >
              <img src={googleIcon} alt="google" className="object-contain" />
              <p className="text-left md:text-center">Continue with Google</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
