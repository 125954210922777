import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const myAdsLoading = () => ({
  type: ActionTypes.MY_ADS_LOADING,
});

export const removeMyAdsData = () => ({
  type: ActionTypes.REMOVE_MY_ADS_DATA,
});

export const fetchGetTotalViews = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/chart`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_TOTAL_VIEWS,
        payload: data,
      });
    });
};

export const fetchGetMyAds = (page, status, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/my_ads?page=${page}${
        status === "Active"
          ? `&status=Active`
          : status === "Inactive"
          ? `&status=Inactive`
          : status === "Featured"
          ? `&featured=1`
          : status === "Completed"
          ? `&is_completed=1`
          : ""
      }`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_ADS,
        payload: data,
      });
    });
};

export const fetchGetAdsForm =
  (cat1, cat2, cat3, cat4, token) => async (dispatch) => {
    await axios
      .get(
        `${BaseUrl}/ads_form?cat1=${cat1}&cat2=${cat2}&cat3=${cat3}&cat4=${cat4}&api_from=classibazaar`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_ADS_FORM,
          payload: data,
        });
      });
  };

export const fetchAddPost = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/ads_form`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.POST_ADS_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.POST_ADS_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetEditForm = (id, token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/ads_edit_form/${id}?api_from=classibazaar`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_EDIT_FORM,
        payload: data,
      });
    });
};

export const fetchUpdateAds = (id, body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/ads_edit_form/${id}`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.EDIT_ADS_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.EDIT_ADS_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetMyInspection = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/my_insepection_lists`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_MY_INSPECTION,
        payload: data,
      });
    });
};

export const fetchGetPaymentTypes = (body, token) => async (dispatch) => {
  const type = body && body.map((val) => `titles[]=${val}`).join("&");
  await axios
    .get(`${BaseUrl}/ads_payment_types_duration?${type}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_PAYMENT_TYPES,
        payload: data,
      });
    });
};

export const fetchGetDeleteReason = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/delete_reasons`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_DELETE_REASON,
        payload: data,
      });
    });
};

export const fetchDeletePost = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/deletepost`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.DELETE_POST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.DELETE_POST_FAIL,
        payload: e?.response?.data,
      });
    });
};
