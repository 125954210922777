import React, { useEffect } from "react";
import { Button, FormikControl } from "../../Component";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
  fetchGetUserProfile,
  fetchVerifyMobile,
  removeProfilesData,
} from "../../Redux/Profile/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import TextError from "../../Component/Formik/Error";
import { flag } from "../../Assets/Assest";

const VerifyNumber = ({ setShowOtpPopup }) => {
  const message = useSelector((state) => state.profile.verifyMsg, shallowEqual);
  const errMessage = useSelector(
    (state) => state.profile.verifyErr,
    shallowEqual
  );

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const initialValues = {
    mobile: "",
  };

  const validationSchema = Yup.object().shape({
    mobile: Yup.string().min(10).max(10).required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      mobile_number: values.mobile,
    };

    dispatch(fetchVerifyMobile(body, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setShowOtpPopup(true);
    message && dispatch(removeProfilesData());
    setTimeout(() => {
      message && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeProfilesData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetUserProfile(token));
    }, 100);
  }, [errMessage]);

  return (
    <div className="w-full mt-10 bg-white border rounded-xl relative">
      <p className="p-5 text-lg font-medium">Verify Your Mobile Number</p>
      <hr className="w-full" />
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formik) => (
          <Form>
            <div className="w-full flex-wrap lg:w-3/4 xl:w-2/3 border flex items-center gap-x-5 p-5">
              <div className="w-full md:w-3/4 mt-7 text-sm">
                <div className="w-full flex items-center gap-x-5 pb-2">
                  <label
                    htmlFor="mobile"
                    className="font-medium flex text-sm items-center justify-between w-full"
                  >
                    Phone Number
                    <ErrorMessage name="mobile" component={TextError} />
                  </label>
                </div>

                <div className="w-full border rounded-lg flex items-center gap-x-3 pl-2">
                  <div className="w-fit flex items-center gap-x-2">
                    <div className="w-7 h-7 rounded-full overflow-hidden">
                      <img
                        src={flag}
                        alt=""
                        className="h-full w-full object-fill"
                      />
                    </div>
                    <p className="text-sm text-gray-500">+977</p>
                  </div>
                  <div className="border h-7"></div>
                  <Field
                    id="mobile"
                    name="mobile"
                    type="number"
                    className=" border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                    placeholder="Enter Your Phone Number"
                    onChange={(e) => {
                      formik.setFieldValue("mobile", e.target.value);
                    }}
                  />
                </div>
              </div>
              <Button
                value="Verify Mobile"
                className="w-fit mt-5 md:mt-14 py-3 px-5 rounded-xl text-sm bg-primary text-white cursor-pointer"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerifyNumber;
