import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { classiBazarLogo } from "../../Assets/Assest";

const MyWishlistCard = ({ val, handleRemoveWishlist }) => {
  const navigate = useNavigate();
  return (
    <div className="w-full mt-5 border rounded-xl bg-white p-3">
      <div className="w-full  flex items-center gap-x-5 flex-wrap md:flex-nowrap">
        <div className="w-full md:w-52 h-40 rounded-lg overflow-hidden">
          {val.thumbnail ? (
            <img
              src={val.thumbnail}
              alt=""
              className="w-full h-full object-fill"
            />
          ) : (
            <img
              src={classiBazarLogo}
              alt=""
              className="w-full h-full object-fill"
            />
          )}
        </div>

        <div className="w-full mt-6 md:mt-0 md:w-2/3 lg:w-3/4">
          <p className="text-base truncate font-medium">{val.title}</p>
          <p className="text-sm text-gray-400 mt-4">
            Category:{" "}
            <span className="font-medium text-black">{val.catname}</span>
          </p>
          <p className="text-sm text-gray-400 mt-2">
            Classi credit rate:{" "}
            <span className="font-medium text-black">
              {val.classi_credit_rate}
            </span>
          </p>

          <p className="text-sm text-gray-400 mt-2">
            Total:{" "}
            <span className="font-medium text-black text-lg">
              Npr {val.price}
            </span>
          </p>
        </div>
      </div>

      <hr className="w-full mt-5" />

      <div className="w-full flex items-center justify-between mt-3">
        <div
          className="w-fit flex items-center gap-x-3 py-2 px-5 text-sm border rounded-lg cursor-pointer"
          onClick={() => handleRemoveWishlist(val.post_id)}
        >
          <AiOutlineCloseCircle className="text-red-600" />
          <p>Remove</p>
        </div>

        <p
          className="text-xs xl:text-sm text-gray-500 flex items-center cursor-pointer hover:underline"
          onClick={() =>
            navigate(`/ads-detail?i=${val.post_id}&name=${val.title}`)
          }
        >
          View Details <MdKeyboardArrowRight />
        </p>
      </div>
    </div>
  );
};

export default MyWishlistCard;
