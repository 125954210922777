import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer, Header } from "./Component";
import {
  Aboutus,
  AdsDetail,
  AgentEnquiry,
  AgentRequest,
  ApiRequest,
  Bikes,
  BlogDetail,
  Blogs,
  Campaign,
  Cars,
  ChangePassword,
  ClassiSupplierRequest,
  Contact,
  Contactus,
  CookiePolicy,
  Coupon,
  Dashboard,
  ForgotPassword,
  Home,
  HowItWorks,
  Jobs,
  ListingPolicy,
  MyAds,
  MyInbox,
  MyInspection,
  NewsDetail,
  NewsPage,
  OfferMessages,
  PageNotFound,
  PaymentHistory,
  PgFlatmates,
  PostAds,
  PrivacyPolicy,
  PrivacySetting,
  Profile,
  RealEstate,
  Referral,
  SavedSearches,
  SearchLList,
  Signin,
  Signup,
  TermsofUse,
  VerifyAccount,
  Wishlist,
} from "./Pages";
import AuthRoute from "./Layout/AuthRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountRoute from "./Layout/Account";

const App = () => {
  return (
    <div className="bg-white box-border min-h-screen relative">
      <BrowserRouter>
        <Header />
        <ToastContainer />
        <Routes>
          {/* login routes */}
          <Route path="/auth" element={<AuthRoute />}>
            <Route path="sign-in" element={<Signin />} />
            <Route path="sign-up" element={<Signup />} />
            <Route path="verify" element={<VerifyAccount />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>

          <Route path="/" element={<Home />} />
          <Route path="/ads-detail" element={<AdsDetail />} />
          <Route path="/search-result" element={<SearchLList />} />
          <Route path="/cars" element={<Cars />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/bikes" element={<Bikes />} />
          <Route path="/real-estate" element={<RealEstate />} />
          <Route path="/pgflatmates" element={<PgFlatmates />} />
          <Route path="/terms-of-use" element={<TermsofUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookie-policy" element={<CookiePolicy />} />
          <Route path="/listing-policy" element={<ListingPolicy />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/my-inbox" element={<MyInbox />} />
          <Route path="/agent-enquiry/:id" element={<AgentEnquiry />} />
          <Route path="/api-request" element={<ApiRequest />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route
            path="/classisupplier-request"
            element={<ClassiSupplierRequest />}
          />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/campaign" element={<Campaign />} />

          {/* Account routes */}
          <Route path="/me" element={<AccountRoute Component={Dashboard} />}>
            <Route path="profile-setting" element={<Profile />} />
            <Route path="coupon-offers" element={<Coupon />} />
            <Route path="my-ads" element={<MyAds />} />
            <Route path="offers" element={<OfferMessages />} />
            <Route path="payments" element={<PaymentHistory />} />
            <Route path="inspections" element={<MyInspection />} />
            <Route path="favourites" element={<Wishlist />} />
            <Route path="saved-search" element={<SavedSearches />} />
            <Route path="privacy-setting" element={<PrivacySetting />} />
            <Route path="referral" element={<Referral />} />
            <Route path="agent-request" element={<AgentRequest />} />
          </Route>
          <Route path="/me/post-ads" element={<PostAds />} />
          <Route path="/edit-form/:id" element={<PostAds />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
