import React from "react";
import { MdLocationOn, MdArrowDropDown } from "react-icons/md";
import Button from "../Button/Button";
import { scrollToTop } from "../NavigateTop";

const Hero = ({
  showLocation,
  setShowLocation,
  location,
  setKeyword,
  handleClickSearch,
}) => {
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleClickSearch();
    }
  };
  return (
    <div className="w-11/12 lg:w-3/4 xl:w-3/5 h-full mx-auto py-5 md:py-10 flex items-center justify-center flex-col">
      <h4 className="font-semibold text-2xl md:text-3xl leading-7">
        <span className="text-primary">Online</span> Market Place
      </h4>
      <p className="text-xs md:text-sm leading-6 mt-5 text-center w-full">
        Classibazaar Nepal is a dynamic online marketplace website that connects
        buyers and sellers, offering a wide range of products and services for
        users to explore and trade with ease.
      </p>

      <div className="w-full md:w-10/12 mx-auto border bg-white rounded-xl mt-5 flex items-center justify-between">
        <div
          className="w-60 border-r h-full py-1 px-3 flex items-center justify-end cursor-pointer"
          onClick={() => {
            scrollToTop();
            setShowLocation(!showLocation);
          }}
        >
          <div className="flex items-center gap-x-2 w-full">
            <MdLocationOn className="text-xl text-primary" />
            <p className="text-sm truncate text-gray-700 w-3/4">
              {location ? location : "Location"}{" "}
            </p>
          </div>
          <MdArrowDropDown
            className={`text-3xl text-gray-500 ${
              location ? "hidden" : "block"
            }`}
          />
        </div>

        <div className="w-full px-3 flex items-center gap-x-3">
          <input
            type="text"
            className="w-full py-1 outline-none border-none bg-white text-sm text-secondary"
            placeholder="What are you looking for"
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </div>
        <Button
          value="Search"
          handleClick={handleClickSearch}
          className="bg-primary w-fit text-white py-3 px-7 text-sm rounded-r-xl"
        />
      </div>
    </div>
  );
};

export default Hero;
