import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";

const LocationPopup = ({
  setLatitude,
  setLongitude,
  setShowLocation,
  setLocation,
}) => {
  const searchVal = localStorage.getItem("Search-value");
  const val = JSON.parse(searchVal);

  const [value, setValue] = useState(val ? val : "");

  const getLocationData = async () => {
    if (value) {
      const search = value.label;

      const promises = await geocodeByAddress(search);

      const geocodes = await Promise.all(promises);

      const place = geocodes?.[0];
      setLocation(place.formatted_address);
      setLatitude(place?.geometry?.location?.lat());
      setLongitude(place?.geometry?.location?.lng());

      const data = {
        location: place.formatted_address,
        latitude: place?.geometry?.location?.lat(),
        longitude: place?.geometry?.location?.lng(),
      };

      localStorage.setItem("search-location", JSON.stringify(data));
      localStorage.setItem("Search-value", JSON.stringify(value));
    }
  };

  useEffect(() => {
    getLocationData();
  }, [value]);

  const handleRemoveLocation = () => {
    localStorage.removeItem("search-location");
    localStorage.removeItem("search-data");
    localStorage.removeItem("Search-value");

    window.location.reload(false);
  };

  return (
    <div className="border w-11/12 left-4 md:w-1/2 md:left-1/4 lg:w-1/3 lg:left-1/3 bg-white top-[5%] rounded-xl z-30 absolute pb-5">
      <div className="flex w-full items-center justify-between font-semibold text-lg  p-5">
        <p>Select Your Location</p>
        <div
          className="w-fit p-2 hover:rounded-md hover:bg-gray-100 cursor-pointer"
          onClick={() => setShowLocation(false)}
        >
          <AiOutlineClose />
        </div>
      </div>

      <hr className="w-full" />
      <div className="px-5 mt-5">
        <div className="text-sm">
          <label className="pb-2 font-medium flex text-sm items-center justify-between w-full">
            <div className="flex items-center gap-x-3">Search Location</div>{" "}
            {value ? (
              <p
                className="text-red-600 text-xs cursor-pointer hover:underline"
                onClick={handleRemoveLocation}
              >
                Remove Location
              </p>
            ) : null}
          </label>

          <GooglePlacesAutocomplete
            placeholder="Where is it?"
            textInputProps={{
              placeholderTextColor: "#32a852",
              returnKeyType: "search",
            }}
            autocompletionRequest={{
              componentRestrictions: { country: ["NP"] },
            }}
            selectProps={{
              value,
              onChange: setValue,
              isMulti: false,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationPopup;
