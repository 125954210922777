import moment from "moment";
import React from "react";
import { BiSolidBadgeCheck } from "react-icons/bi";
import { LiaDownloadSolid } from "react-icons/lia";

const PaymentCard = ({ val }) => {
  return (
    <div className="w-full mt-10 border rounded-xl bg-white">
      {/* header */}
      <div className="w-full p-3 flex items-center justify-between">
        <div className="w-full flex items-center gap-x-5">
          {val.spotlight === "1" && (
            <h6 className="w-fit rounded-full border text-xs py-1 px-3 border-blue-500 bg-blue-100 text-blue-500">
              Spotlight
            </h6>
          )}

          {val.urgent === "1" && (
            <h6 className="w-fit rounded-full border text-xs py-1 px-3 border-red-500 bg-red-100 text-red-500">
              Urgent
            </h6>
          )}

          {val.featured === "1" && (
            <h6 className="w-fit rounded-full border text-xs py-1 px-3 border-green-600 bg-green-100 text-green-600">
              Featured
            </h6>
          )}
        </div>

        <div className="w-fit flex items-center gap-x-2">
          <BiSolidBadgeCheck className="text-primary" />
          <p className="text-primary text-sm">{val.payment_status}</p>
        </div>
      </div>
      <hr className="w-full" />

      {/* contain */}
      <div className="w-full p-3">
        <div className="w-full  flex items-center gap-x-5 flex-wrap md:flex-nowrap">
          <div className="w-full md:w-52 h-40 rounded-lg overflow-hidden">
            <img
              src={val.image}
              alt=""
              className="w-full h-full object-cover md:object-fill"
            />
          </div>

          <div className="w-full mt-6 md:mt-0 md:w-2/3 lg:w-3/4">
            <p className="text-base truncate font-medium">{val.title}</p>
            <p className="text-sm text-gray-400 mt-4">
              Payment Method:{" "}
              <span className="font-medium text-black">
                {val.payment_method}
              </span>
            </p>
            <p className="text-sm text-gray-400 mt-2">
              Classi credit rate:{" "}
              <span className="font-medium text-black">
                {val.classi_credit_rate}
              </span>
            </p>

            <p className="text-sm text-gray-400 mt-2">
              Total:{" "}
              <span className="font-medium text-black">Npr {val.price}</span>
            </p>
          </div>
        </div>

        <hr className="w-full mt-5" />

        <div className="w-full flex items-center justify-between mt-3">
          <p className="text-sm text-gray-500">
            Payment on {moment(val.payment_date).format("DD-MM-YYYY")}
          </p>

          <a
            target="_blank"
            href={val.payment_pdf}
            download={val.payment_pdf}
            className="w-fit flex items-center gap-x-3 py-2 px-5 text-sm border rounded-lg cursor-pointer"
          >
            <LiaDownloadSolid className="text-primary text-lg" />
            <p>Download CSV</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
