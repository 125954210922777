import React, { useEffect, useState } from "react";
import { Categories, Head, Loading, LocationPopup } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchGetBlogsDetail, removeBlogsData } from "../../Redux/Blogs/action";
import { fetchGetAllCategories } from "../../Redux/Category/action";
import moment from "moment";
import { scrollToTop } from "../../Component/NavigateTop";

const BlogDetail = () => {
  const [showCategories, setShowCategories] = useState(false);

  const [showLocation, setShowLocation] = useState(false);

  const data = localStorage.getItem("search-location");
  const parsedLocationData = JSON.parse(data);

  const [latitude, setLatitude] = useState(
    parsedLocationData ? parsedLocationData.latitude : ""
  );
  const [longitude, setLongitude] = useState(
    parsedLocationData ? parsedLocationData.longitude : ""
  );
  const [locations, setLocations] = useState(
    parsedLocationData ? parsedLocationData.location : ""
  );

  const [key, setKey] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.blogs.isloading, shallowEqual);
  const catLoading = useSelector(
    (state) => state.categories.isloading,
    shallowEqual
  );

  const detail = useSelector((state) => state.blogs.detail, shallowEqual);

  const handleClickSearch = () => {
    if (latitude && longitude) {
      const filter = [
        {
          label: "latitudes[]",
          value: latitude,
        },
        {
          label: "longitudes[]",
          value: longitude,
        },
        {
          label: "location",
          value: locations,
        },
      ];
      localStorage.setItem("search-data", JSON.stringify(filter));
      navigate(`/search-result?genre=all&type=offering&key=${key}`);
    } else {
      navigate(`/search-result?genre=all&type=offering&key=${key}`);
    }
  };

  const removeTags = (str) => {
    if (str === null || str === "") return false;
    else str = str?.toString();

    return str?.replace(/(<([^>]+)>)/gi, "");
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetBlogsDetail(id));
    dispatch(fetchGetAllCategories());
    return () => {
      dispatch(removeBlogsData());
    };
  }, [id]);

  if (loading || catLoading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 relative">
      {showCategories || showLocation ? (
        <div
          className="absolute w-full h-full bg-black opacity-10"
          onClick={() => {
            setShowCategories(false);
            setShowLocation(false);
          }}
        ></div>
      ) : null}

      {showLocation && (
        <div className="w-full absolute top-28">
          <LocationPopup
            setShowLocation={setShowLocation}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            setLocation={setLocations}
            location={locations}
          />
        </div>
      )}

      <Categories showCategories={showCategories} />
      <Head
        setShowCategories={setShowCategories}
        color="#2E8B4A"
        showLocation={showLocation}
        setShowLocation={setShowLocation}
        location={locations}
        setKeyword={setKey}
        handleClickSearch={handleClickSearch}
      />
      <hr className="w-full mt-7" />
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <h5 className="w-full text-2xl font-medium">{detail.title}</h5>

        <div className="w-full flex items-center gap-x-3 mt-4">
          <p>{moment(detail.added_date).format("ll")}</p>
          <p>/</p>
          <p>By: {detail.added_by} </p>
        </div>
        <div className="w-full h-[24rem] md:h-[26rem] mt-5">
          <img
            src={detail.picture}
            alt=""
            className="w-full h-full object-cover"
          />
        </div>
        <p className="mt-5 md:mt-7 text-sm md:text-base">
          {removeTags(detail.content)}
        </p>
      </div>
    </div>
  );
};

export default BlogDetail;
